import { Grid, Typography, Breadcrumbs, Tabs, Tab, Dialog, TableContainer, Paper, Table, TableRow, TableHead, TableCell, TableBody, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';
import CustomDataGrid from '../../../products/stockComponents/customDataGrid';
import { useEffect, useState } from 'react';
import HttpComponent from '../../../School/MakeRequest';
import DateFormatter from '../../../../utils/dateFormatter';
import ExportMenu from '../../../School/ExportMenu'

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Zed-Pocket Money</Typography>,
    <Typography key={3} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Reports</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Student Wallet Balance</Typography>
];

let localCurrency = localStorage.getItem('localCurrency') || 'KES';

const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency }).format(value);

export default function StudentWalleteReportsAdminDrillDown() {
    const navigate = useNavigate();
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, debitData: [], creditData: [], modifiedDebitCount: 0, modifiedCreditCount: 0, page: 1, pageSize: dataGridPageSize, });
    const [tabValue, setTabValue] = useState(0);
    const [studentName, setStudentName] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); 

    const queryParams = decodeURIComponent(window.location.search);
    const queryParamValue = queryParams?.slice(1);
    const [searchvalue, admissionNumber, businessNumber] = queryParamValue.split("?");

    const debitColumns = [
        {
            field: "receiptNo", headerName: "Receipt No", flex: 1,
            renderCell: (params) => (
                <Typography
                    onClick={() => handleReceiptClick(params.row)}
                    style={{ color: '#007bff', cursor: 'pointer', textDecoration: 'underline' }}
                >
                    {params.value}
                </Typography>
            )
        },
        { field: "orderNo", headerName: "Order Number", flex: 1 },
        { field: "transactionType", headerName: "Transaction Type", flex: 1 },
        { field: "source", headerName: "Source", flex: 1 },
        { field: "createdAt", headerName: "Date & Time", flex: 1 },
        { field: "transAmount", headerName: "Total Amount", flex: 1 }
    ];

    const creditColumns = [
        {
            field: "receiptNo", headerName: "Receipt No", flex: 1,
            // renderCell: (params) => (
            //     <Typography
            //         onClick={() => handleReceiptClick(params.row)}
            //         style={{ color: '#007bff', cursor: 'pointer', textDecoration: 'underline' }}
            //     >
            //         {params.value}
            //     </Typography>
            // )
        },
        { field: "transactionType", headerName: "Transaction Type", flex: 1 },
        { field: "source", headerName: "Source", flex: 1 },
        {
            field: "phone", headerName: "Phone Number", flex: 1,
            renderCell: (params) => (
                <Typography>{maskPhoneNumber(params.value)}</Typography>
            )
        },
        { field: "createdAt", headerName: "Date & Time", flex: 1 },
        { field: "transAmount", headerName: "Total Amount", flex: 1 }
    ];

    const filedata = (tabValue === 0 ? pageState.debitData : pageState.creditData).map((data) => {
        if (tabValue === 0) {
            return {"Receipt No": data?.receiptNo,"Order Number": data?.orderNumber,"Transaction Type": data?.transactionType,"Source": data?.source,"Date & Time": DateFormatter(data?.createdAt),"Total Amount": numberFormat(data?.transAmount)};
        } else {
            return {"Receipt No": data?.receiptNo,"Transaction Type": data?.transactionType,"Source": data?.source,"Phone Number": data?.phoneNumber,"Date & Time": DateFormatter(data?.createdAt),"Total Amount": numberFormat(data?.transAmount)};
        }
    });
    console.log('filedata:', filedata);
    
    const fileHeaders = tabValue === 0 ? 
        [["Receipt No", "Order Number", "Transaction Type", "Source", "Date & Time", "Total Amount"]] :
        [["Receipt No", "Transaction Type", "Source", "Phone Number", "Date & Time", "Total Amount"]];
    
    const csvColumns = tabValue === 0 ?
        [
            { label: "Receipt No", key: "Receipt No" },
            { label: "Order Number", key: "Order Number" },
            { label: "Transaction Type", key: "Transaction Type" },
            { label: "Source", key: "Source" },
            { label: "Date & Time", key: "Date & Time" },
            { label: "Total Amount", key: "Total Amount" },
        ] :
        [
            { label: "Receipt No", key: "Receipt No" },
            { label: "Transaction Type", key: "Transaction Type" },
            { label: "Source", key: "Source" },
            { label: "Phone Number", key: "Phone Number" },
            { label: "Date & Time", key: "Date & Time" },
            { label: "Total Amount", key: "Total Amount" },
        ];
    
    const fileDataPDF = (tabValue === 0 ? pageState.debitData : pageState.creditData).map((data) => {
        if (tabValue === 0) {
            return [data?.receiptNo,data?.orderNumber,data?.transactionType,data?.source,DateFormatter(data?.createdAt),numberFormat(data?.transAmount)];
        } else {
            return [data?.receiptNo,data?.transactionType,data?.source,data?.phoneNumber,DateFormatter(data?.createdAt),numberFormat(data?.transAmount)];
        }
    });
    console.log('fileDataPDF...', fileDataPDF)    

    function handleGetStudentTransactionList() {
        setPageState({ ...pageState, isLoading: true })
        HttpComponent({
            method: 'GET',
            url: `/api/getZedPayItWalletReports?studentNumber=${admissionNumber}&businessId=${businessNumber}&page=${pageState.page}&limit=${dataGridPageSize}`,
            token: localStorage.getItem('X-Authorization')
        }).then((data) => {
            if (data?.status === 200 || data?.status === 201) {
                setStudentName(data?.response?.studentName);
                setPageState({
                    ...pageState,
                    debitData: data?.response?.modifiedDebit || [],
                    creditData: data?.response?.modifiedCredit || [],
                    isLoading: false,
                    modifiedDebitCount: data?.response?.modifiedDebitCount,
                    modifiedCreditCount: data?.response?.modifiedCreditCount,
                });
            }
        }).catch((error) => {
            console.error('Error fetching transactions', error);
        });
    }    

    useEffect(() => {
        handleGetStudentTransactionList();
    }, [admissionNumber, businessNumber, pageState?.page, dataGridPageSize,]);

    const formatData = (data) => data.map((config) => ({
        id: config._id,
        receiptNo: config?.receiptNo,
        transactionType: config?.transactionType,
        source: config?.source,
        createdAt: DateFormatter(config?.createdAt),
        transAmount: numberFormat(config?.transAmount),
        orderNo: tabValue === 0 ? config?.orderNumber : undefined,
        phone: tabValue === 1 ? config?.phoneNumber : undefined,
        items: config?.items || [] 
    }));

    const filteredData = tabValue === 0 ? formatData(pageState.debitData) : formatData(pageState.creditData);
    // console.log('Fetching with pageSize:', pageState.pageSize);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const maskPhoneNumber = (phone) => {
        if (!phone) return '';
        const length = phone.length;
        if (length <= 6) return phone;
        return phone.slice(0, length - 6) + '****' + phone.slice(length - 2); 
    };

    const handleReceiptClick = (rowData) => {
        console.log("Receipt Clicked: ", rowData);
        setSelectedItem(rowData);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedItem(null);
    };

    return (
        <Grid container direction={'column'}>
            <Grid item mt={2} display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosNewIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "20px", color: "#032541" }}>{admissionNumber} {studentName}</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            
            <Grid item container mt={2} justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="Debit Credit Tabs" TabIndicatorProps={{ style: { backgroundColor: 'rgb(220, 53, 69)' } }}>
                        <Tab label="Debit" sx={{ color: tabValue === 0 ? 'rgb(220, 53, 69)' : '#000', fontWeight: tabValue === 0 ? 600 : 400, fontFamily: 'Poppins', '&.Mui-selected': { color: 'rgb(220, 53, 69)' } }} />
                        <Tab label="Credit" sx={{ color: tabValue === 1 ? 'rgb(220, 53, 69)' : '#000', fontWeight: tabValue === 1 ? 600 : 400, fontFamily: 'Poppins', '&.Mui-selected': { color: 'rgb(220, 53, 69)' } }} />
                    </Tabs>
                </Grid>
                <Grid item>
                    <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'Student Wallet Report'} title={'Student Wallet Report'} />
                </Grid>
            </Grid>
            
            <Grid item mt={2} width={'100%'}>
                <CustomDataGrid
                    customStyles={{ border: "none", height: "550px", width: "100%" }}
                    column={tabValue === 0 ? debitColumns : creditColumns} 
                    setPageSize={setPageSize}
                    setPageState={setPageState}
                    pageState={pageState}
                    dataGridPageSize={dataGridPageSize}
                    row={filteredData}  // Pass filtered data here
                />
            </Grid>
    
            {/* Modal for displaying product details */}
            <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth="md">
                <DialogTitle>Receipt Details</DialogTitle>
                <DialogContent>
                    {selectedItem?.items?.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No</TableCell> 
                                        <TableCell>Product Name</TableCell>
                                        <TableCell>Quantity</TableCell>
                                        <TableCell>Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedItem.items.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell> 
                                            <TableCell>{item.productName}</TableCell>
                                            <TableCell>{item.quantity}</TableCell>
                                            <TableCell>{numberFormat(item.amount)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Typography>No items available</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Close</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );    
}
