import { Grid, Typography, FormControl, InputLabel, MenuItem, TextField, Select, Breadcrumbs } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import HttpComponent from '../../../School/MakeRequest';
import { useEffect, useState } from 'react';
import { useNavigate , Link } from 'react-router-dom'
import jsondecode from "jwt-decode";
import SearchFilter from '../../../customerAccounts/customerComponents/searchFilter';
import ExportMenu from '../../../School/ExportMenu';
import CustomDataGrid from '../../../products/stockComponents/customDataGrid';
import StudentWalleteReportsAdminDrillDown from '../Reports/StudentWalletdrilldown';

// breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={3} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Reports</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Student Wallete Balance Report</Typography>
]

export default function ZpmStudentWalleteBal() {
    const navigate = useNavigate()
     // get query params!;
     const queryParams = decodeURIComponent(window.location.search);
     const queryParamValue = queryParams?.slice(1);
     const state = queryParamValue.split("?")[0]
    const [searchValue, setSearchValue] = useState('')
    const [allBusiness, setAllBusiness] = useState([])
    const [selectedValue, setSelectValue] = useState("");
    const [selectInputValue, setInputValue] = useState("");
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [handleDaysChange, setHandleDaysChange] = useState("")
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });


    let localCurrency = localStorage.getItem('localCurrency')
    const localToken = localStorage.getItem("X-Authorization");
    const decoded = jsondecode(localToken)
    if (!localCurrency) {
        localCurrency = decoded?.localCurrency
    }
    if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined) {
        localCurrency = 'KES'
    }

    const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency }).format(value);


    useEffect(() => {
        const setLastSevenDays = () => {
            const result = [];
            for (let i = 0; i < 7; i++) {
                const d = new Date();
                d.setDate(d.getDate() - i);
                result.push(d.toLocaleDateString());
            }
            const lastSevenFirstStart = result[0];
            const lastSevenLastEnd = result[result.length - 1];
            setStartDate(lastSevenFirstStart);
            setEndDate(lastSevenLastEnd);
        };

        setLastSevenDays();
    }, []);



    useEffect(() => {
        switch (handleDaysChange) {
            case "Today":
                const today = new Date().toLocaleDateString();
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1)).toLocaleDateString();
                console.log(yeseterday, 'yeseterday');
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last 7 days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0]).toLocaleDateString();
                    const lastSevenLast = new Date(result[result.length - 1]).toLocaleDateString();

                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1).toLocaleDateString();
                const currentDay = new Date().toLocaleDateString();
                setStartDate(firstDay);
                setEndDate(currentDay);
                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1).toLocaleDateString();
                const lastDayLastMonth = new Date(date2.setDate(1)).toLocaleDateString();
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            default:
                function defaultLast7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0]).toLocaleDateString();
                    const lastSevenLast = new Date(result[result.length - 1]).toLocaleDateString();

                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                defaultLast7Days();
                break;
        }
    }, [handleDaysChange]);

    // get all business

    function getAllBusiness() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/get_available_businesses?searchValue=${selectedValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    const foundBusiness = data?.response?.data?.filter?.((business) => business?.businessCategory === 'School').map((business) => {
                        return {
                            name: business.businessName,
                            bsNo: business?.businessNumber,
                        }
                    })
                    setAllBusiness(foundBusiness)
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getAllBusiness();
    }, [selectedValue])


    function getStudentBalance() {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/getStudentBalance?page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${escape(searchValue)}`,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getStudentBalance()
    }, [pageState?.page, dataGridPageSize, searchValue,])


    const configData = pageState?.data?.map((config) => ({
        id: config._id,
        studentName: config?.studentName,
        admissionNumber: config?.admissionNumber,
        balance: numberFormat(config?.balance),
        businessNumber: config?.businessNumber,
    }))

    const studentBalanceCols = [
        { field: "studentName", headerName: "Student Name", flex: 1 },
        { field: "admissionNumber", headerName: "Student Reg No", flex: 1 ,renderCell:(params)=><Link to={`/school/studentwalletereports?viewwalletedrilldown?${params?.row?.admissionNumber}?${params?.row?.businessNumber}`}>{params?.row?.admissionNumber}</Link> },
        { field: 'balance', headerName: 'Current Wallet Balance', flex: 1 }
    ]
    


    // export data

    const filedata = pageState?.data?.map((data) => {
        return {
            "Student Name": data?.studentName,
            "Student Reg No": data?.admissionNumber,
            "Current Wallet Balance": numberFormat(data?.balance),
        }
    })
    const fileHeaders = [["Student Name", "Student Reg No", "Current Wallet Balance"]]
    const csvColumns = [
        { label: "Student Name", key: "Student Name" },
        { label: "Student Reg No", key: "Student Reg No" },
        { label: "Current Wallet Balance", key: "Current Wallet Balance" },
    ];

    const fileDataPDF = pageState?.data?.map((data) => [data?.studentName, data?.admissionNumber, data?.businessNumber, numberFormat(data?.balance)]);

    return (
        <Grid direction={'column'} container>
         {state === 'viewwalletedrilldown' ? <StudentWalleteReportsAdminDrillDown/>:<>
            <Grid item display={'flex'} alignItems={'center'}>
                <ArrowBackIosNewIcon style={{ color: "#707070" }} />
                <Typography style={{ color: "#707070", fontWeight: 'bold', fontSize: "20px", fontFamily: "Poppins" }}>Zed - Pocket Money - Institution Admin</Typography>
            </Grid>
            <Grid item display={'flex'} direction={'column'} mt={2}>
                <Typography style={{ color: "#032541", fontWeight: 'bold', margin: '10px 0px', fontSize: "25px", fontFamily: "Poppins" }}>Student Wallete Balance Report</Typography>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2} display={'flex'} width={'100%'}>
                <Grid container width={'100%'} justifyContent={'space-between'} gap={2} display={'flex'} direction={'row'} alignItems={'center'}>
                    <Grid item>
                        <SearchFilter placeholder={"Search"} setInputValue={setSearchValue} />
                    </Grid>
                    {/* <Grid item>
                        <AutocompleteComponent setSelectValue={setSelectValue} setInputValue={setInputValue} label={"Search business.."} data={allBusiness ? allBusiness : [{ name: "default", bsNo: 546778 }]} styles={{ marginLeft: "10px", width: '300px' }} />
                    </Grid> */}
                    <Grid item mx={1} display={'flex'} alignItems={'center'} style={{ width: '200px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="startDate"
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item mx={1} display={'flex'} alignItems={'center'} style={{ width: '200px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="endDate"
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item display={'flex'}>
                        <FormControl sx={{ width: '190px' }}>
                            <InputLabel id="demo-simple-select-label">Date</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Date"
                                value={handleDaysChange}
                                onChange={(e) => setHandleDaysChange(e.target.value)}
                            >
                                {/* <MenuItem value='All'>All</MenuItem> */}
                                <MenuItem value={'Today'}>Today</MenuItem>
                                <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                <MenuItem value={'This Month'}>This Month</MenuItem>
                                <MenuItem value={'Last Month'}>Last Month</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'All Transactions'} title={'Student wallete report'} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} width={'100%'}>
                <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={studentBalanceCols} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
            </Grid>
            </>}
        </Grid>
    )
}