import { Box, Breadcrumbs, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material"
import React, { useEffect, useState } from "react";
import HttpComponent from "../../../School/MakeRequest";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { currencyconverter } from "../../../../common/currencyconverter";
import SearchFilter from "../../../customerAccounts/customerComponents/searchFilter";
import ExportMenu from "../../../School/ExportMenu";
import CustomDataGrid from "../../../products/stockComponents/customDataGrid";
import { useNavigate } from "react-router-dom";
import CashierDrillDownPayit from "./cashierdrilldownpayit";
import CashierOrderDetails from "./cashierOrderdetails";
import CashIcon from '../../../../common/images/coin.svg'
import CardIcon from '../../../../common/images/cardr.svg'
import MobileIcon from '../../../../common/images/fingerprint-icn.svg'
import SelforderIcon from '../../../../common/images/MobileMoney.svg'
import ZPMPaymentMethodDrillDowns from "./zpmcarddrilldowns";


const baseUrl = process.env.REACT_APP_BASE_URL;




const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Zed Payit Report</Typography>,
]

const PayitReportZedSchool = () => {
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize, total: 0, noOfOrders: 0 , alltransTypes:[]});
    const [orderNumber, setOrderNumber] = useState('');
    const [selectedCashier, setSelectedCashier] = useState('');
    const [payitUsers, setPayitUsers] = useState([])
    const [selectedpayitUser, setSelectedpayitUser] = useState('')
    const [cashiers, setCashiers] = useState([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [handleDaysChange, setHandleDaysChange] = useState("This Month")
    const navigate = useNavigate()

    const queryParams = decodeURIComponent(window.location.search);
    const newqueryParamValue = queryParams.slice(1);
    const [queryParamState] = newqueryParamValue.split("?")
    const transactionTypes = ['card' ,'bio' ,'SELFORDER','Cash Payment']
    // date filter

    useEffect(() => {
        switch (handleDaysChange) {
            case "Today":
                const today = new Date()
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1))
                console.log(yeseterday, 'yeseterday');
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last 7 days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0])
                    const lastSevenLast = new Date(result[result.length - 1])

                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1)
                const currentDay = new Date()
                setStartDate(firstDay);
                setEndDate(currentDay);
                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1)
                const lastDayLastMonth = new Date(date2.setDate(1))
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            default:
                break;
        }
    }, [handleDaysChange]);

    // fetch data

    const fetchPayitData = async () => {
        setPageState({ ...pageState, isLoading: true })
        try {
            HttpComponent({
                method: "GET",
                url: `/api/v1/groupedorderbycashier?page=${pageState.page}&limit=${dataGridPageSize}&startDate=${startDate}&endDate=${endDate}`,
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                   // console.log(data?.response, 'data >>>')
                    setPageState({ ...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.cashiersCount, total: data?.response?.totalAmount, noOfOrders: data?.response?.count ,alltransTypes:data?.response?.paymentsMethods })

                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            setPageState({ ...pageState, isLoading: false })
        }

    }

    useEffect(() => {
        fetchPayitData();
    }, [pageState.page, dataGridPageSize, orderNumber, startDate, endDate, selectedCashier]);

    const getBackgroundColor = (transactionType) => {
        switch (transactionType) {
            case 'SELFORDER':
                return `rgb(240,251,247)`;
            case 'card':
                return `rgb(242,248,252)`;
            case 'bio':
                return `rgb(253,250,247)`;
            case 'Cash Payment':
                 return `rgb(242,246,248)`;
            default:
                return `rgb(240,251,247)`;
        }
    };

    const getImage = (transactionType) => {
        switch (transactionType) {
            case 'bio':
            return MobileIcon
            case 'card':
                return CardIcon
            case 'SELFORDER':
                return SelforderIcon
            case 'Cash Payment':
                return CashIcon
            default:
                return ''
        }
    }
    const getextColor = (transactionType) => {
        switch (transactionType) {
            case 'bio':
                return `#ff8503`;
            case 'card':
                return `#1186c4`;
            case 'SELFORDER':
                return `#009447`;
            case 'Cash Payment':
                return `#032541`;
            default:
                return `#000000`;
        }
    };
            



    const fetchPayitUsers = async () => {
        try {
            HttpComponent({
                method: "GET",
                url: "/api/listZedPayItUsers",
                token: localStorage.getItem("X-Authorization"),
            }).then((data) => {
                if (data?.status === 200 || data?.status === 201) {
                    setPayitUsers(data?.response?.data)
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
        }

    }

    useEffect(() => {
        fetchPayitUsers()
    }, [])

    const fetchCashiers = async () => {
        try {
            const response = await fetch(baseUrl + "/api/get_cashiers_by_business", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            });
            const data = await response.json();
            //console.log(`datadata ${JSON.stringify(data)}`);
            if (response?.status === 200 && data?.Status === "SUCCESS") {
                setCashiers(data.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchCashiers();
    }, []);





    const configData = pageState?.data?.map((config) => ({
        id: config._id,
        nOrders: config?.nOrders,
        name: config?.name,
        totalAmount: currencyconverter(config?.totalAmount),
    }))

    const payitOrders = [
        { field: 'name', headerName: 'Cashier Name', flex: 1, renderCell: (params) => <Typography onClick={()=>navigate(`/school/zedpayitreports?CashierDrillDown?${params?.row?.id}?${params?.row?.name}`)} style={{ fontFamily: "Poppins", fontSize: "14px", textDecoration:'underline',cursor:'pointer', color: '#000', fontWeight: 500 }}>{params?.value}</Typography> },
        { field: 'nOrders', headerName: 'No of Orders', flex: 1 },
        { field: 'totalAmount', headerName: 'Amount', flex: 1 },
    ]

    const filedata = pageState?.data?.map((data) => {
        return {
            "No of Orders": data?.nOrders,
            "Cashier Name": data?.name,
            "Amount": currencyconverter(data?.totalAmount),
        }
    })
    const fileHeaders = [["No of Orders", "Cashier Name", "Amount"]]
    const csvColumns = [
        { label: "Cashier Name", key: "Cashier Name" },
        { label: "No of Orders", key: "No of Orders" },
        { label: "Amount", key: "Amount" },
    ];

    const fileDataPDF = pageState?.data?.map((data) => [data?.name, data?.nOrders, currencyconverter(data?.totalAmount)]);

    return (
        <Grid container direction={'column'}>
            {queryParamState === "CashierDrillDown" ? <CashierDrillDownPayit /> : queryParamState === 'OrderDetails' ? <CashierOrderDetails/>: queryParamState === 'pm' ? <ZPMPaymentMethodDrillDowns/>: <React.Fragment>
                <Grid item display={'flex'} alignItems={'center'}>
                    <ArrowBackIosNewIcon  style={{ color: "#032541" }} />
                    <Typography style={{ color: "#032541", fontWeight: 'bold', fontSize: "20px", fontFamily: "Poppins" }}>Zed Payit Report</Typography>
                </Grid>
                <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={2}>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                    <Box component={'div'} display={'flex'} gap={2} alignItems={'center'}>
                        <Paper elevation={0} sx={{ background: '#f5f5f5', paddingY: 1, paddingX: 1 }}>
                            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }} fontSize={'13px'}> No of Orders <span style={{ fontWeight: 700 }}>{pageState.noOfOrders}</span></Typography>
                        </Paper>
                        <Paper elevation={0} sx={{ background: '#f5f5f5', paddingY: 1, paddingX: 1 }}>
                            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }} fontSize={'13px'}> No of cashiers <span style={{ fontWeight: 700 }}>{pageState.count}</span></Typography>
                        </Paper>
                        <Paper elevation={0} sx={{ background: '#f5f5f5', paddingY: 1, paddingX: 1 }}>
                            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }} fontSize={'13px'}> Total Amount <span style={{ fontWeight: 700 }}>{currencyconverter(pageState.total)}</span></Typography>
                        </Paper>
                    </Box>
                </Grid>
                <Grid container spacing={2} mt={2}>
                    {transactionTypes.map((item) => {
                        const resultFound = pageState.alltransTypes?.find((type) => type?._id === item);
                        const totalCount = resultFound?.count || 0;
                        const backgroudColor = getBackgroundColor(item);
                        const transIcon  = getImage(item);
                        const textc = getextColor(item);
                        return (
                            <Grid onClick={()=>navigate(`/school/zedpayitreports?pm?${item}`)} sx={{cursor:'pointer'}} item xs={12} sm={6} md={3} key={item}>
                                <Paper elevation={0} sx={{ background:backgroudColor, padding: 2, height: '150px' }}>
                                   <Box component={'div'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Box component={'div'}>
                                            <Typography sx={{fontWeight:700}}>{item === 'SELFORDER' ? 'Mobile Money':item}</Typography>
                                            <Typography fontWeight={700} py={2} color={textc}>{currencyconverter(resultFound?.total ? resultFound?.total:0 )}</Typography>
                                            <Typography>{totalCount} transactions</Typography>
                                        </Box>
                                        <Box component={'div'} display={'flex'}  alignItems={'center'}>
                                            <img src={transIcon} alt="icon" style={{ background: backgroudColor, width: '40px', height: '40px', borderRadius: '20px' }}  />
                                        </Box>
                                   </Box>
                                </Paper>
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid item mt={2} display={'flex'} alignItems={'center'} width={'100%'}>
                    <Box component={'div'} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                        <Box component={'div'} width={'30%'}>
                            <SearchFilter placeholder={"Search"} setInputValue={setOrderNumber} />
                        </Box>
                        <Box component={'div'} display={'flex'} gap={2} alignItems={'center'}>
                            <Grid item display={'flex'}>
                                <FormControl sx={{ width: '190px', height: "50px" }}>
                                    <InputLabel id="demo-simple-select-label">Date</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Date"
                                        value={handleDaysChange}
                                        sx={{ height: '100%' }}
                                        onChange={(e) => setHandleDaysChange(e.target.value)}
                                    >
                                        <MenuItem value={'Today'}>Today</MenuItem>
                                        <MenuItem value={'Yesterday'}>Yesterday</MenuItem>
                                        <MenuItem value={'Last 7 days'}>Weekly</MenuItem>
                                        <MenuItem value={'This Month'}>This Month</MenuItem>
                                        <MenuItem value={'Last Month'}>Last Month</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item display={'flex'}>
                                <FormControl sx={{ width: '190px', height: '50px' }}>
                                    <InputLabel id="demo-simple-select-label">Cashier</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Cashier"
                                        value={selectedCashier}
                                        sx={{ height: '100%' }}
                                        onChange={(e) => setSelectedCashier(e.target.value)}
                                    >
                                        {payitUsers?.map((cashier) => (
                                            <MenuItem value={cashier?.userId}>{cashier?.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'All Transactions'} title={'Student wallete report'} />
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <Grid item mt={2} width={'100%'}>
                    <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={payitOrders} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
                </Grid>
            </React.Fragment>}
        </Grid>
    )
}

export { PayitReportZedSchool }